<template>
  <div>
    <el-dialog
      :title="dialogFormTitle"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
      :center="true"
      top="20vh"
      :close-on-click-modal="false"
      :before-close="() => closeEntertainClientDialog()"
      v-loading.fullscreen.lock="login_loading"
    >
      <div>
        <el-form
          :model="EntertainClient"
          :rules="rules"
          ref="EntertainClientEditForm"
          label-width="100px"
          size="small"
        >
        <!-- 添加按钮的弹框1 -->
          <div v-if="show_type">
            <el-form-item label="客户名称" prop="name">
              <el-input v-model="EntertainClient.name" clearable></el-input>
            </el-form-item>
          </div>
          <!-- 添加成功后详情弹框0 -->
          <div v-else>
            <el-descriptions
              class="margin-top"
              :column="1"
              :labelStyle="{ width: '120px', 'text-align': 'center' }"
              :contentStyle="{ 'text-align': 'center' }"
              border
            >
              <el-descriptions-item>
                <template slot="label">
                  <span>客户名称</span>
                </template>
                <span>{{EntertainClient.name}}</span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-form>
      </div>
      <!-- 底部按钮展示 -->
      <div slot="footer" class="dialog-footer">
        <!-- 详情底部按钮0 -->
        <div v-if="show_type === 0">
          <el-button
            v-if="$_has(edit_auth)"
            type="primary"
            plain
            @click="$emit('show_edit')"
            >修改</el-button
          >
          <el-button
            v-if="$_has(del_auth)"
            type="danger"
            plain
            @click="EntertainClientDel('EntertainClientEditForm')"
            >删除</el-button
          >
          <el-button plain @click="closeEntertainClientDialog()"
            >关闭</el-button
          >
        </div>
        <!-- 添加底部按钮1 -->
        <div v-else-if="show_type === 1">
          <el-button
            type="success"
            plain
            @click="submitInsert('EntertainClientEditForm')"
            >确定</el-button
          >
          <el-button type="warning" plain @click="resetForm()">重置</el-button>
          <el-button plain @click="closeEntertainClientDialog()"
            >关闭</el-button
          >
        </div>
        <!-- 修改底部按钮2 -->
        <div v-else>
          <el-button
            type="success"
            plain
            @click="submitEdit('EntertainClientEditForm')"
            >确定修改</el-button
          >
          <el-button plain @click="closeEntertainClientDialog()"
            >取消操作</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  entertain_client_add_request,
  entertain_client_del_request,
  entertain_client_edit_request,
} from "@/network/finance/EntertainClient.js";

export default {
  name: "EntertainClientEditComponent",
  data() {
    return {
      login_loading: false,
      EntertainClient: {
        name: "",
        id: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "客户姓名必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    dialogFormTitle() {
      if (this.show_type === 0) {
        return "客户详情";
      } else if (this.show_type === 1) {
        return "添加客户";
      } else {
        return "修改客户";
      }
    },
  },
  methods: {
    closeEntertainClientDialog() {
      this.resetForm();
      this.$emit("exitEntertainClientDialog");
    },
    submitInsert(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.EntertainClientInsert(formName);
      });
    },
    EntertainClientInsert(formName) {
      this.login_loading = true;
      entertain_client_add_request(this.EntertainClient)
        .then((s) => {
          this.login_loading = false;
          if (s.status === 0) {
            this.$message.success("添加成功!");
            this.$emit("exitEntertainClientDialog");
            this.$emit("search");
            this.$emit("details_row", s.result.id * 1);
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.login_loading = false;
          this.$message.error(err);
        });
    },
    resetForm() {
      this.EntertainClient = {
        name: "",
      };
    },
    submitEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.EntertainClientEdit(formName);
      });
    },
    EntertainClientEdit(formName) {
      this.login_loading = true;
      this.EntertainClient.id = this.id;
      entertain_client_edit_request(this.EntertainClient)
        .then((s) => {
          this.login_loading = false;
          if (s.status === 0) {
            this.$message.success("修改成功!");
            this.$emit("exitEntertainClientDialog");
            this.$emit("search");
            this.$emit("details_row", this.EntertainClient.id);
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.login_loading = false;
          this.$message.error(err);
        });
    },
    EntertainClientDel(formName) {
      this.$confirm(
        "是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          entertain_client_del_request(this.id)
            .then((s) => {
              if (s.status === 0) {
                this.$message.success("删除成功!");
                this.$emit("search");
              } else {
                this.$message.error(s.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            })
            .finally(() => this.closeEntertainClientDialog());
        })
        .catch((err) => {});
    },
  },
  filters: {},
  props: {
    dialogFormVisible: Boolean,
    id: {
      type: Number,
      default() {
        return 0;
      },
    },

    entertain_client_details_data: {
      type: Object,
      default() {
        return {};
      },
    },
    show_type: {
      type: Number,
      default() {
        return 0;
      },
    },
    edit_auth: String,
    del_auth: String,
  },
  created() {},
  destoryed() {},
  mounted() {},
  updated() {},
  components: {},
  watch: {
    entertain_client_details_data(newVal) {
      this.EntertainClient = newVal;
    },
  },
};
</script>

<style lang="less"></style>
