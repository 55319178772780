import request from '../request'

//招待客户列表
export const entertain_client_index_request = p => {
    return request({
        method:'GET',
        url:'entertain_client/index',
        params: p
    })
}

//添加招待客户
export const entertain_client_add_request = d => {
    return request({
        method:'POST',
        url:'entertain_client/add',
        data: d
    })
}

//删除招待客户
export const entertain_client_del_request = id => {
    return request({
        method:'DELETE',
        url:'entertain_client/del',
        data: {
            id: id
        }
    })
}

//修改招待客户
export const entertain_client_edit_request = d => {
    return request({
        method:'PUT',
        url:'entertain_client/edit',
        data: {
            id: d.id,
            name: d.name
        }
    })
}

//获取招待客户详情
export const entertain_client_details_request = id => {
    return request({
        method:'GET',
        url:'entertain_client/details',
        params: {
            id
        }
    })
}